import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import BackupImage from '../components/BackupImage'
import {Sash} from '../components/Sashes'
import { graphql, Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import SEO from '../components/seo'

const NotForgotten = ({ data }) => {

  const title = 'Not forgotten'
  const description = data.wpQuiltBlock ? data.wpQuiltBlock.quiltBlockFields.description : 'This quilt block was intentionally left blank to honor all the unknown African Americans who have contributed to our shared culinary identity.'

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          <SEO title={`Not Forgotten - MOFAD`} />
          {!modal && <Navbar />}
          <section className="max-w-screen-xl mx-auto z-50">
            {modal && (
              <Link
                className="absolute top-0 right-0 p-4 text-red text-sm uppercase tracking-widest font-semibold hover:text-gray-500 transition-colors duration-100"
                to={closeTo}
                state={{
                  noScroll: true,
                }}
              >
                Close X
              </Link>
            )}
            <div
              className={`flex flex-col md:flex-row mb-32 ${
                modal ? 'pt-10' : 'p-5 md:p-10'
              } `}
            >
              <div className="md:w-1/2">
                <div className="relative w-full mb-5">
                    <BackupImage
                      className="w-full h-full object-cover pl-12 pb-12"
                      category="agriculture"
                    />
                    <Sash className="absolute inset-0 z-1 w-full h-full" />
                </div>
              </div>
              <div className="md:w-1/2 md:px-10">
              <h1 className="text-3xl mb-1 font-bold leading-tight">
                  {title}
              </h1>
                <div className="text-lg">
                  {description}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default NotForgotten

export const query = graphql`
  query notForgotten {
    wpQuiltBlock(slug: {eq: "empty"}) {
      slug
      quiltBlockFields {
        description
      }
    }
  }
`
